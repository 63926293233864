import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/shopHall',
    component: () => import('@/views/shopHall.vue')
  },
  {
    path: '/goodDetail',
    component: () => import('@/views/goodDetail.vue')
  },
  {
    path: '/aboutUs',
    component: () => import('@/views/aboutUs.vue')
  },
  {
    path: '/shopJoin',
    component: () => import('@/views/shopJoin.vue')
  },
  {
    path: '/edi',
    component: () => import('@/views/edi.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory('/buyper'),
  routes
})

export default router
