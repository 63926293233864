import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import {post, get, upload, downloadget, downloadpost, findHttpUrl } from './utils/http.js'
//  使用中文
// import 'dayjs/locale/zh-cn'
// import locale from 'element-plus/lib/locale/lang/zh-cn'
import './assets/style.css'  //  引入全局CSS

const app = createApp(App);
app.config.globalProperties.$post = post
app.config.globalProperties.$get = get
app.config.globalProperties.$upload = upload
app.config.globalProperties.$downloadget = downloadget
app.config.globalProperties.$downloadpost = downloadpost
app.config.globalProperties.$findHttpUrl = findHttpUrl

app.use(router);
app.use(store);
app.use(ElementPlus);
app.mount('#app');

// createApp(App).use(router).use(store).mount('#app')
